<template>
  <div id="calls">

    <header>
      <img src="../assets/images/new-image/concorsando-white.png" alt="Logo" class="logo">
    </header>


  </div>
</template>

<script>
import 'bootstrap'

export default {
  name: "GenericComponent",
  components: {},
  }
</script>

